<script setup lang="ts">
const { isLogged } = storeToRefs(useUser())
</script>

<template>
  <div basis="1/2" px-16px my-40px>
    <h1 heading1 font-700 mt-28px mb-8px tracking-tight leading-tight>
      <span block text-44px sm:text-60px md:text-52px lg:text-60px xl:text-64px>Rychlá a jednoduchá směna kryptoměn</span>
    </h1>
    <div flex flex-wrap gap-x-8px gap-y-0px text-36px sm:text-36px md:text-32px font-500>
      <div flex items-center gap-8px hover:bg-primary-10 rounded-lg>
        <UnoIcon i-fubex-stopwatch inline-block text-primary-500 dark:text-primary-300 h-30px w-30px />rychle
      </div>
      <div flex items-center gap-8px hover:bg-primary-10 rounded-lg>
        <UnoIcon i-fubex-lightning inline-block text-primary-500 dark:text-primary-300 h-28px w-28px mx-3px />jednoduše
      </div>
      <div flex items-center gap-8px hover:bg-primary-10 rounded-lg>
        <UnoIcon i-fubex-safe inline-block text-primary-500 dark:text-primary-300 h-26px w-26px mx-1px />bezpečně
      </div>
    </div>
    <p paragraph-md md:paragraph-lg mt-32px text-neutral-700>
      Směňte své koruny za krypto ihned a bez skrytých poplatků
    </p>
    <div flex justify-center md:justify-start items-center gap-16px mt-32px mb-48px flex-wrap>
      <WidgetAuthDialogs v-if="!isLogged" default-view="register">
        <FButton name="create" size="lg" w-full md:w-auto prefix-icon="i-fubex-user">
          Vytvořit účet
        </FButton>
      </WidgetAuthDialogs>
      <FButton v-if="isLogged" name="account" size="lg" w-full md:w-auto prefix-icon="i-fubex-user" to="/profile">
        Můj účet
      </FButton>
    </div>
  </div>
</template>
